// import React from 'react' // Assuming you're using Tabler icons
import { Button } from '@mantine/core'
import { IconShare } from '@tabler/icons-react'
import { useFileContext } from '../context/FileContext.tsx'

function ExportPDFButton() {
    // use state pdfURL from FileContext
    const { pdfUrl } = useFileContext()

    // Fallback method to download the file when File System Access API is not available or fails
    const openPDF = () => {
        if (!pdfUrl) {
            return
        }
        if (navigator.canShare) {
            const sharePDF = async () => {
                try {
                    const response = await fetch(pdfUrl)
                    const buffer = await response.arrayBuffer()

                    const pdf = new File([buffer], 'exported.pdf', {
                        type: 'application/pdf',
                    })
                    const files = [pdf]

                    if (navigator.canShare({ files })) {
                        await navigator.share({ files })
                    } else {
                        console.error('Sharing not supported for these files')
                    }
                } catch (error) {
                    console.error('Error sharing PDF:', error)
                }
            }

            sharePDF()
        } else {
            const a = document.createElement('a')
            a.href = pdfUrl
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }

    return (
        <Button
            onClick={() => openPDF()}
            leftSection={<IconShare size={14} />}
            variant="gradient"
            radius="md"
            color="var(--bright-color)"
            size="sm"
            disabled={!pdfUrl}
        >
            Export PDF
        </Button>
    )
}

export default ExportPDFButton
