import React, { useState, useEffect, useRef } from 'react'
import * as Tone from 'tone'
import { Instrument } from './Instrument.tsx'
import { ParsedPiece } from './core/ParsedPiece.tsx'
import { SelectedPlayBackItemDesc } from './core/LineMap.tsx'
import { PlaybackItem } from './core/PlaybackItem.tsx'
import deepEqual from 'deep-equal'
import { useFileContext } from './context/FileContext.tsx'

export interface MultiInstrumentPlayerProps {
    parsedPiece: ParsedPiece | null
    selectedPlaybackItemDesc: SelectedPlayBackItemDesc | null
    doPlayAudio: boolean
}

const MultiInstrumentPlayer: React.FC<MultiInstrumentPlayerProps> = ({
    parsedPiece,
    doPlayAudio,
    selectedPlaybackItemDesc,
}) => {
    const [parts, setParts] = useState<Tone.Part[] | null>(null)
    // const [playbackItem, setPlaybackItem] = useState<PlaybackItem>(null)
    const prevSelectedPlaybackItem = useRef<PlaybackItem | null>(null)
    const prevInstrumentConf = useRef<never | null>(null)

    const { instruments, setInstruments, bpm } = useFileContext()

    useEffect(() => {
        Tone.Transport.bpm.value = bpm
    }, [bpm])

    useEffect(() => {
        const startTone = async () => {
            await Tone.start()
            console.log('Audio context started')
            document.removeEventListener('click', startTone)
            document.removeEventListener('keydown', startTone)
        }

        document.addEventListener('click', startTone, { once: true })
        document.addEventListener('keydown', startTone, { once: true })

        return () => {
            document.removeEventListener('click', startTone)
            document.removeEventListener('keydown', startTone)
        }
    }, [])

    useEffect(() => {
        if (!parsedPiece) return

        const instrumentConf = parsedPiece.instrument_conf

        // return if instrumentConf has not changed to avoid reloading instruments
        if (deepEqual(instrumentConf, prevInstrumentConf.current)) return
        // @ts-expect-error ???
        prevInstrumentConf.current = instrumentConf

        const loadInstrument = async () => {
            const instrumentNames = Object.keys(instrumentConf.instrument_specs)
            const newInstruments: { [key: string]: Instrument } = {}

            for (const instrumentName of instrumentNames) {
                const channelNames =
                    instrumentConf.channel_names_by_instrument[instrumentName]

                // @ts-expect-error ???
                for (const channelName of channelNames) {
                    const samplepackName = channelName
                        .split('_')
                        .slice(0, -1)
                        .join('_')
                    const specUrl = `soundsprites/${samplepackName}.json`
                    const audioUrl = `soundsprites/${samplepackName}.webm`

                    try {
                        const response = await fetch(specUrl)
                        const spec = await response.json()
                        newInstruments[channelName] = new Instrument(
                            channelName,
                            spec,
                            audioUrl,
                            instrumentConf.default_panning[channelName],
                            instrumentConf.default_volumes[channelName]
                        )
                        await newInstruments[channelName].loadPromise
                    } catch (error) {
                        console.error(
                            'Error loading instrument or spec:',
                            error
                        )
                    }
                }
            }
            setInstruments(newInstruments)
        }

        loadInstrument()
    }, [parsedPiece])

    // useEffect(() => {
    //     Tone.Transport.bpm.value = parsedPiece?.bpm
    // }, [parsedPiece])

    const handlePlayPause = async (
        isPlaybackItemChanged: boolean,
        playbackItem: PlaybackItem
    ) => {
        // console.log('selectedPlaybackItemDesc', selectedPlaybackItemDesc)
        if (!parsedPiece) return
        if (!playbackItem) {
            Tone.Transport.stop()
            return
        }
        if (!instruments) return // wait for instruments to load

        // console.log('handlePlayPause', isPlaybackItemChanged)

        // update parts if playback item has changed
        if (isPlaybackItemChanged || !parts) {
            // stop all parts
            parts?.forEach((part) => part.stop())

            Tone.Transport.timeSignature = parsedPiece.n_pulses_per_beat

            const newParts: Tone.Part[] = []
            for (const instrumentId in playbackItem) {
                // @ts-expect-error fix types
                for (const track of playbackItem[instrumentId]) {
                    if (!instruments[instrumentId]) return
                    await instruments[instrumentId].loadPromise
                    const part = await instruments[instrumentId].create_part(
                        track.times,
                        track.sounds,
                        track.length
                    )

                    newParts.push(part)
                }
            }
            setParts(newParts)
            // console.log('updated Parts', newParts)

            // if (doPlayAudio) {
            // start all parts
            newParts.forEach((part) => part.start(0))
            // }

            console.log('Parts changed')
        }

        if (!doPlayAudio) {
            // stop all parts
            Tone.Transport.stop()
            // parts?.forEach((part) => part.stop())
        } else {
            console.log('Tone.Transport.state', Tone.Transport.state)
            if (Tone.Transport.state !== 'started') {
                // console.log(
                //     'Awaiting Tone.start(), then starting parts and Tone.Transport'
                // )
                await Tone.start()
                // parts?.forEach((part) => part.start(0))
                Tone.Transport.start()
                console.log('Tone.Transport started')
            }
        }

        // // restart parts if already started
        // parts?.forEach((part) => {
        //     part.stop()
        //     part.start(0)
        // })

        // if (!isPlaying || isPlaybackItemChanged) {
        //     if (isPlaying) {
        //         parts.forEach((part) => part.stop())
        //         Tone.Transport.stop()
        //     }
        //
        //     console.log(isPlaybackItemChanged, playbackItem)
        //
        //     Tone.Transport.timeSignature = parsedPiece.n_pulses_per_beat
        //
        //     const newParts: Tone.Part[] = []
        //     for (let instrumentId in playbackItem) {
        //         for (let track of playbackItem[instrumentId]) {
        //             console.log('track', track)
        //             const part = await instruments[instrumentId].create_part(
        //                 track.times,
        //                 track.sounds,
        //                 track.length
        //             )
        //             part.start(0)
        //             newParts.push(part)
        //         }
        //     }
        //     setParts(newParts)
        //     console.log('Awaiting Tone.start(), then starting Tone.Transport')
        //     await Tone.start()
        //     Tone.Transport.start()
        //
        //     if (!isPlaying) {
        //         setIsPlaying(true)
        //     }
        // } else {
        //     parts.forEach((part) => part.stop())
        //     Tone.Transport.stop()
        //     setIsPlaying(false)
        // }
    }

    useEffect(() => {
        if (!parsedPiece || !selectedPlaybackItemDesc) return

        // console.log('selectedPlaybackItemDesc', selectedPlaybackItemDesc)

        const playbackItem =
            parsedPiece.playback_items[
                selectedPlaybackItemDesc.element_name ?? 'Standard'
            ]

        const isSelectedPlaybackItemDescChanged =
            playbackItem !== prevSelectedPlaybackItem.current

        prevSelectedPlaybackItem.current = playbackItem

        handlePlayPause(isSelectedPlaybackItemDescChanged, playbackItem)
        // if (doPlayAudio && !isPlaying) {
        //     handlePlayPause(false)
        // } else if (!doPlayAudio && isPlaying) {
        //     handlePlayPause(false)
        // }
    }, [selectedPlaybackItemDesc, parsedPiece, doPlayAudio])

    return null
}

export default MultiInstrumentPlayer
