import * as Tone from 'tone'

interface Spec {
    [id: string]: {
        start: number
        duration: number
    }
}

class Instrument {
    name: string
    spec: Spec
    players: { [id: string]: Tone.Player }
    loaded: boolean
    volume: number
    channel: Tone.Channel
    buffer: Tone.ToneAudioBuffer | null
    loadPromise: Promise<void>

    constructor(
        name: string,
        spec: Spec,
        audioUrl: string,
        stereo_pan: number,
        volume: number
    ) {
        this.name = name
        this.spec = spec
        this.players = {}
        this.loaded = false
        this.volume = volume
        this.channel = new Tone.Channel(volume, stereo_pan)
        this.channel.toDestination()
        this.buffer = null

        this.loadPromise = new Promise((resolve, reject) => {
            this.buffer = new Tone.Buffer(
                audioUrl,
                () => {
                    for (const id in this.spec) {
                        const sample = this.spec[id]
                        const startTime = sample.start / 1000
                        const duration = sample.duration / 1000

                        const player = new Tone.Player({
                            // @ts-expect-error tone js
                            url: this.buffer,
                            loop: false,
                            autostart: false,
                        }).connect(this.channel)

                        // @ts-expect-error tone js
                        player.sampleStart = startTime
                        // @ts-expect-error tone js
                        player.sampleDuration = duration
                        this.players[id] = player
                    }
                    this.loaded = true
                    resolve()
                },
                (error) => {
                    console.error(
                        `Error loading buffer for ${this.name}:`,
                        error
                    )
                    reject(error)
                }
            )
        })
    }

    mute() {
        this.channel.mute = true
    }

    unmute() {
        this.channel.mute = false
    }

    isMuted() {
        return this.channel.mute
    }

    async play(id: string, time: number, verbose = false) {
        if (!this.loaded) {
            await this.loadPromise
        }
        const player = this.players[id]
        if (player) {
            // @ts-expect-error tone js
            player.start(time, player.sampleStart, player.sampleDuration)
            if (verbose) {
                console.log(
                    `Playing sample ID: ${id} at time: ${Tone.Transport.position}`
                )
            }
        } else {
            console.error(`Sample ID "${id}" of "${this.name}" not found.`)
        }
    }

    async create_part(
        starts: number[],
        notes: string[],
        length: number
    ): Promise<Tone.Part> {
        if (!this.loaded) {
            await this.loadPromise
        }

        const playback_spec = starts.map((start, index) => [
            `0:0:${start}`,
            notes[index].toString(),
        ])
        const loopEnd = `0:0:${length}`

        const part = new Tone.Part((time, value) => {
            this.play(value, time)
        }, playback_spec)
        part.loop = true
        part.loopEnd = loopEnd
        return part
    }
}

export { Instrument }
