/* eslint-disable @typescript-eslint/no-unused-vars */
import Split from 'react-split-grid'
import { MantineProvider, Stack } from '@mantine/core'
import { useMediaQuery, usePrevious } from '@mantine/hooks'
import '@mantine/core/styles.css'
import './App.css'

// import CodeMirror, {
//     ChangeSpec,
//     EditorState,
//     Transaction,
// } from '@uiw/react-codemirror'
// import { keymap } from '@codemirror/view'
import CodeMirror from '@uiw/react-codemirror'
import { markdown } from '@codemirror/lang-markdown'
import React, { useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import axios from 'axios'
import IframePDFViewer from './components/IframePDFViewer.tsx'
// import { AudioComponent } from './AudioComponent.tsx'
import MultiInstrumentPlayer from './MultiInstrumentPlayer.tsx'
// import { EditorView } from 'codemirror'
// import { createRoot } from 'react-dom/client'
import { HeaderMenu } from './components/HeaderMenu.tsx'
// import CodeMirrorEditor from './components/CodeMirrorEditor.tsx'
import { PlaybackBar } from './components/PlaybackBar.tsx'
import { useFileContext } from './context/FileContext.tsx'
import * as Sentry from '@sentry/react'
import RightSidebarContents from './components/RightSidebarContents.tsx'

// https://www.privacypolicies.com/live/47d6f0f5-3d92-4ad2-b2e5-0e78430148ed

// function saveTextAsFile(text: string) {
//     const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
//     const url = URL.createObjectURL(blob)
//
//     const a = document.createElement('a')
//     a.href = url
//     a.download = 'piece.md' // Set the file name here
//     document.body.appendChild(a)
//     a.click()
//     document.body.removeChild(a)
//     URL.revokeObjectURL(url)
// }

function App() {
    const {
        editorContent,
        setEditorContent,
        parsedPiece,
        setParsedPiece,
        debouncedEditorContent,
        selectedLine,
        setSelectedLine,
        selectedPlaybackItem,
        setSelectedPlaybackItem,
        doPlayAudio,
        setDoPlayAudio,
        setBpm,
    } = useFileContext()

    const reloadPattern = (val: string) => {
        axios
            .post('/api/piece/parse/', val, {
                headers: { 'Content-Type': 'text/plain' },
            })
            .then(
                (response) => {
                    const data = response.data
                    setParsedPiece(data)
                    // console.log(data)
                },
                (error) => {
                    console.log(error)
                    Sentry.captureException(error)
                }
            )
    }

    const previousePieceBpm = usePrevious(parsedPiece?.bpm)
    useEffect(() => {
        // update bpm from piece if it changes there
        if (parsedPiece) {
            if (parsedPiece.bpm !== previousePieceBpm || !previousePieceBpm) {
                setBpm(parsedPiece.bpm)
            }
        }
    }, [parsedPiece])

    useEffect(() => {
        reloadPattern(debouncedEditorContent)
    }, [debouncedEditorContent])

    // useEffect(() => {
    //     console.log(doPlayAudio)
    // }, [doPlayAudio])

    // @ts-expect-error ignore for now
    const onChange = React.useCallback((val: string, viewUpdate) => {
        setEditorContent(val)
    }, [])
    //
    // // @ts-expect-error ignore for now
    // const newHandleFileChange = (fileContents, fileName) => {
    //     // console.log(fileContents, fileName)
    //     setEditorContent(fileContents)
    // }

    // // @ts-expect-error ignore for now
    // const handleFileChange = (event) => {
    //     const file = event.target.files[0]
    //     if (!file) return
    //
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //         // @ts-expect-error ignore for now
    //         const contents = e.target.result
    //         console.log(contents)
    //         // @ts-expect-error ignore for now
    //         setEditorContent(contents)
    //     }
    //     reader.readAsText(file)
    // }

    // useHotkeys(
    //     'mod+s, cmd+s, ctrl+s',
    //     (e) => {
    //         e.preventDefault()
    //         console.log('saving file')
    //         saveTextAsFile(editorContent)
    //     },
    //     // @ts-expect-error ignore for now
    //     { preventDefault: true },
    //     [editorContent]
    // )

    // useHotkeys(
    //     'mod+o, cmd+o, ctrl+o',
    //     (e) => {
    //         e.preventDefault()
    //         console.log('opening file')
    //         // @ts-expect-error ignore for now
    //         document.getElementById('text-input').click()
    //     },
    //     // @ts-expect-error ignore for now
    //
    //     { preventDefault: true }
    // )

    useHotkeys(
        'ctrl+shift=p, cmd+shift+p',
        (e) => {
            e.preventDefault()
            setDoPlayAudio(!doPlayAudio)
        },
        [doPlayAudio]
    )

    // useEffect(() => {
    //     const matchDark = window.matchMedia('(prefers-color-scheme: dark)')
    //     const updateTheme = (e: MediaQueryList | MediaQueryListEvent) => {
    //         setTheme(e.matches ? 'dark' : 'light')
    //     }
    //     updateTheme(matchDark)
    //     matchDark.addEventListener('change', updateTheme)
    // })

    useEffect(() => {
        // console.log('line selected:', selectedLine, 'parsed piece', parsedPiece)
        if (!parsedPiece) {
            setSelectedPlaybackItem(null)
            return
        }

        if (!(selectedLine.toString() in parsedPiece.line_map)) {
            const first = parseInt(Object.keys(parsedPiece.line_map)[0])
            const last = parseInt(Object.keys(parsedPiece.line_map)[-1])
            if (selectedLine > first && selectedLine <= last)
                setSelectedPlaybackItem(null)
        } else {
            setSelectedPlaybackItem(
                parsedPiece.line_map[selectedLine.toString()]
            )
        }
    }, [selectedLine, parsedPiece])

    // Custom command to duplicate lines
    // const duplicateLine = ({
    //     state,
    //     dispatch,
    // }: {
    //     state: EditorState
    //     dispatch: (tr: Transaction) => void
    // }) => {
    //     console.log('duplicate lines')
    //     const changes: ChangeSpec[] = []
    //     for (const range of state.selection.ranges) {
    //         if (range.empty) {
    //             const line = state.doc.lineAt(range.head)
    //             changes.push({ from: line.from, insert: line.text + '\n' })
    //         } else {
    //             const text = state.doc.sliceString(range.from, range.to)
    //             changes.push({ from: range.from, insert: text })
    //         }
    //     }
    //
    //     if (changes.length) {
    //         dispatch(state.update({ changes }))
    //         return true
    //     }
    //     return false
    // }
    // Custom keymap extension
    // const extraKeymap = keymap.of([{ key: 'Cmd-D', run: duplicateLine }])

    //
    //     {/*<CodeMirrorEditor*/}
    // {/*    editorContent={editorContent}*/}
    // {/*    setEditorContent={setEditorContent}*/}
    // {/*    onChange={onChange}*/}
    // {/*    onStatistics={(s) => {*/}
    // {/*        if (!s.selectedText)*/}
    // {/*            setSelectedLine(*/}
    // {/*                s.lineNumber - 1*/}
    // {/*            )*/}
    // {/*    }}*/}
    // {/*/>*/}
    //

    const pdfViewer = (
        <div className="split-pane" id="pdf-viewer">
            <IframePDFViewer text={debouncedEditorContent}></IframePDFViewer>
        </div>
    )

    const editorDivContents = (
        <div className="split-pane" id="left-pane">
            <HeaderMenu />
            <CodeMirror
                value={editorContent}
                // height="100vh"
                // height="100%"
                extensions={[markdown()]}
                onChange={onChange}
                onStatistics={(s) => {
                    if (!s.selectedText) setSelectedLine(s.line.number - 1)
                }}
                theme={'dark'}
            />
            <PlaybackBar
                doPlayAudio={doPlayAudio}
                setDoPlayAudio={setDoPlayAudio}
                selectedPlaybackItem={selectedPlaybackItem}
            />
        </div>
    )

    // const isMobilePortrait = useMediaQuery(
    //     '(max-width: 768px) and (orientation: portrait)'
    // )

    const isMobile = useMediaQuery('(max-width: 768px)')

    // const gridstyle = useMemo(() => {
    //     return isMobilePortrait ? 'grid grid-vertical' : 'grid grid-horizontal'
    // }, [isMobilePortrait])
    //
    // const gutterstyle = useMemo(() => {
    //     return isMobilePortrait
    //         ? 'resize-gutter gutter-row gutter-row-1'
    //         : 'resize-gutter gutter-col gutter-col-1'
    // }, [isMobilePortrait])

    const griddivhorizontal = useRef(null)
    // const griddivvertical = useRef(null)

    const grid = isMobile ? (
        <>{editorDivContents}</>
    ) : (
        <Split
            //@ts-expect-error split plane missing typescript definition
            render={({
                //@ts-expect-error split plane missing typescript definition
                getGridProps,
                //@ts-expect-error split plane missing typescript definition
                getGutterProps,
            }) => (
                <div
                    ref={griddivhorizontal}
                    className="grid grid-horizontal"
                    {...getGridProps()}
                >
                    {editorDivContents}

                    <div
                        className="resize-gutter gutter-col gutter-col-1"
                        {...getGutterProps('column', 1)}
                    />

                    {pdfViewer}

                    <div
                        className="resize-gutter gutter-col gutter-col-2"
                        {...getGutterProps('column', 3)}
                    />

                    <div className="split-pane" id="audio-sidebar">
                        <RightSidebarContents />
                    </div>
                </div>
            )}
        />
    )

    // useEffect(() => {
    //     if (griddivvertical.current) {
    //         // @ts-expect-error ....
    //         griddivvertical.current.style = null
    //     }
    //     if (griddivhorizontal.current) {
    //         // @ts-expect-error ....
    //         griddivhorizontal.current.style = null
    //     }
    // }, [isMobilePortrait])

    return (
        <MantineProvider defaultColorScheme="dark">
            <MultiInstrumentPlayer
                parsedPiece={parsedPiece}
                selectedPlaybackItemDesc={selectedPlaybackItem}
                doPlayAudio={doPlayAudio}
            />
            <Stack gap={'0'} className={'mainStack'}>
                {grid}
            </Stack>
        </MantineProvider>
    )
}

export default App
