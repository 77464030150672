import { Group, ActionIcon } from '@mantine/core'
import classes from './PlaybackBar.module.css'
import { IconVolume, IconVolumeOff } from '@tabler/icons-react'
import { SelectedPlayBackItemDesc } from '../core/LineMap.tsx'

interface PlaybackBarProps {
    doPlayAudio: boolean
    // eslint-disable-next-line @typescript-eslint/ban-types
    setDoPlayAudio: Function
    selectedPlaybackItem: SelectedPlayBackItemDesc | null
}

export function PlaybackBar(props: PlaybackBarProps) {
    return (
        <Group justify="flex-start" className={classes.playbackbar} gap="xs">
            <ActionIcon
                onClick={() => props.setDoPlayAudio(!props.doPlayAudio)}
                variant={props.doPlayAudio ? 'subtle' : 'filled'}
                color={
                    props.doPlayAudio
                        ? 'var(--bright-color)'
                        : 'var(--bg-color)'
                }
                radius={'md'}
            >
                {props.doPlayAudio ? (
                    <IconVolume
                        style={{ width: '70%', height: '70%' }}
                        stroke={2}
                    />
                ) : (
                    <IconVolumeOff
                        style={{ width: '70%', height: '70%' }}
                        stroke={2}
                    />
                )}
            </ActionIcon>
            <span className={classes.playbackbarlabel}>
                {props.selectedPlaybackItem &&
                    (props.selectedPlaybackItem.element_name || 'Standard')}
            </span>
        </Group>
    )
}
