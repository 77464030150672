import { Button, Group, Slider, Space, Stack, Text, Title } from '@mantine/core'
import classes from './HeaderSimple.module.css'
import { useFileContext } from '../context/FileContext.tsx'
import { IconPlayerPlayFilled, IconPlayerStopFilled } from '@tabler/icons-react'

export default function RightSidebarContents() {
    const {
        instruments,
        selectedPlaybackItem,
        doPlayAudio,
        setDoPlayAudio,
        bpm,
        setBpm,
    } = useFileContext()

    return (
        <>
            <Group
                className={classes.header}
                gap="xs"
                justify="center"

                // style={{ width: '100%' }}
            ></Group>

            <Stack
                gap="s"
                style={{
                    paddingLeft: '14px',
                    paddingRight: '14px',
                }}
            >
                <Space mt="s" h="l" />
                <Title
                    size="l"
                    fs={!selectedPlaybackItem ? 'italic' : 'normal'}
                >
                    {selectedPlaybackItem
                        ? selectedPlaybackItem.element_name || 'Standard'
                        : 'Select in editor...'}
                </Title>
                <Button
                    size="sm"
                    onClick={() => setDoPlayAudio(!doPlayAudio)}
                    variant="gradient"
                    // variant={doPlayAudio ? 'subtle' : 'gradient'}
                    // color={
                    //     doPlayAudio ? 'var(--bright-color)' : 'var(--bg-color)'
                    // }
                    radius={'md'}
                    leftSection={
                        !doPlayAudio ? (
                            <IconPlayerPlayFilled size={14} />
                        ) : (
                            <IconPlayerStopFilled size={14} />
                        )
                    }
                    disabled={!selectedPlaybackItem}
                >
                    {!doPlayAudio ? 'Play' : 'Stop'}
                </Button>

                {/*<MultiSelect data={parsedPiece?.playback_items} value={selectedPlaybackItem?.element_name} onChange={*/}
                {/*    (value) => {*/}
                {/*        console.log(value)*/}
                {/*        // const newSelectedPlaybackItem = parsedPiece?.playback_items.find((item) => item.element_name === value)*/}
                {/*        // if (newSelectedPlaybackItem) {*/}
                {/*        //     setSelectedPlaybackItem(newSelectedPlaybackItem)*/}
                {/*        // }*/}
                {/*} />*/}

                {/*<Space h="s" />*/}

                <Stack>
                    <Text fw={700}>Tempo</Text>
                    <Slider
                        defaultValue={bpm}
                        // marks={[{ value: 0 }, { value: -30 }]}

                        step={1}
                        min={60}
                        max={170}
                        marks={[
                            { value: 80, label: '80' },
                            { value: 115, label: '115' },
                            { value: 150, label: '150' },
                        ]}
                        label={(value) => `${value} bpm`}
                        onChange={setBpm}
                        value={bpm}
                    />
                </Stack>
                <Space mt="xs" h="l" />

                <Text fw={700}>Volume</Text>
                {
                    // one button to toggle mute/unmute of each instrument
                    instruments &&
                        Object.keys(instruments).map((instrumentName) => {
                            const instrument = instruments[instrumentName]
                            return (
                                // <Button
                                //     key={instrumentName}
                                //     onClick={() => {
                                //         const newMuteState = { ...muteState }
                                //         if (instrument.isMuted()) {
                                //             instrument.unmute()
                                //             newMuteState[instrumentName] = false
                                //         } else {
                                //             instrument.mute()
                                //             newMuteState[instrumentName] = true
                                //         }
                                //         setMuteState(newMuteState)
                                //     }}
                                //     variant="default"
                                // >
                                //     {instrument.isMuted() ? 'Unmute' : 'Mute'}{' '}
                                //     {instrumentName}
                                // </Button>

                                <Stack gap="xs" key={instrumentName}>
                                    <Text
                                        // ml="sm"
                                        size="sm"
                                        // ta="center"
                                        fw={600}
                                        lineClamp={1}
                                    >
                                        {instrumentName
                                            .replace(/^h1?_/, '')
                                            .replace(/1/g, ' ')
                                            .replace(/_/g, ' ')
                                            .replace(/\b\w/g, (char) =>
                                                char.toUpperCase()
                                            )}
                                    </Text>
                                    <Slider
                                        ml="sm"
                                        mr="sm"
                                        defaultValue={
                                            instrument.channel.volume.value
                                        }
                                        marks={[
                                            { value: 0 },
                                            { value: -30 },
                                            {
                                                value: instrument.channel.volume
                                                    .value,
                                            },
                                        ]}
                                        step={0.1}
                                        min={-30}
                                        max={6}
                                        label={(value) =>
                                            value === -30
                                                ? '-∞ dB'
                                                : `${value.toFixed(1)} dB`
                                        }
                                        // scale={(value) =>
                                        //     Math.sign(value) *
                                        //     Math.log1p(Math.abs(value))
                                        // }
                                        onChange={(value) => {
                                            instrument.channel.volume.value =
                                                value
                                            console.log(value, value === -30)
                                            if (value === -30) {
                                                instrument.mute()
                                            } else {
                                                instrument.unmute()
                                            }
                                        }}
                                    />
                                </Stack>
                            )
                        })
                }
                <Space h="l" />

                {/*<Button*/}
                {/*    size="xs"*/}
                {/*    onClick={() => {*/}
                {/*        if (!parsedPiece) return*/}
                {/*        for (const instrumentName in instruments) {*/}
                {/*            const instrument = instruments[instrumentName]*/}
                {/*            instrument.channel.volume.value =*/}
                {/*                parsedPiece.instrument_conf.default_volumes[*/}
                {/*                    instrumentName*/}
                {/*                ]*/}
                {/*        }*/}
                {/*    }}*/}
                {/*>*/}
                {/*    Reset volumes*/}
                {/*</Button>*/}
            </Stack>
        </>
    )
}
