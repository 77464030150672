import React, { createContext, useContext, useState } from 'react'
import { initial_editor_content } from '../IntialEditorContent.tsx'
import { useDebouncedValue } from '@mantine/hooks'
import { ParsedPiece } from '../core/ParsedPiece.tsx'
import { SelectedPlayBackItemDesc } from '../core/LineMap.tsx'
import { Instrument } from '../Instrument.tsx'

// Define the type for the context state
interface FileContextType {
    editorContent: string
    setEditorContent: (text: string) => void
    fileName: string
    setFileName: (fileName: string) => void
    debouncedEditorContent: string
    parsedPiece: ParsedPiece | null
    setParsedPiece: (piece: ParsedPiece | null) => void
    selectedLine: number
    setSelectedLine: (line: number) => void
    selectedPlaybackItem: SelectedPlayBackItemDesc | null
    setSelectedPlaybackItem: (item: SelectedPlayBackItemDesc | null) => void
    doPlayAudio: boolean
    setDoPlayAudio: (val: boolean) => void
    pdfUrl: string | null
    setPdfUrl: (url: string | null) => void
    instruments: { [key: string]: Instrument }
    setInstruments: (instruments: { [key: string]: Instrument }) => void
    bpm: number
    setBpm: (bpm: number) => void
}

// Create the context with a default value
const FileContext = createContext<FileContextType | undefined>(undefined)

// Custom hook to use the file context
export function useFileContext() {
    const context = useContext(FileContext)
    if (!context)
        throw new Error('useFileContext must be used within a FileProvider')
    return context
}

// Provider component
export const FileProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [fileName, setFileName] = useState<string>('piece.txt')
    const [editorContent, setEditorContent] = React.useState(
        initial_editor_content
    )
    const [debouncedEditorContent] = useDebouncedValue(editorContent, 300)

    const [parsedPiece, setParsedPiece] = useState<ParsedPiece | null>(null)

    // const [theme, setTheme] = useState('dark')

    const [selectedLine, setSelectedLine] = useState<number>(0)

    const [selectedPlaybackItem, setSelectedPlaybackItem] =
        useState<SelectedPlayBackItemDesc | null>(null)

    const [doPlayAudio, setDoPlayAudio] = useState<boolean>(false)

    const [pdfUrl, setPdfUrl] = useState<string | null>(null)

    const [instruments, setInstruments] = useState<{
        [key: string]: Instrument
    }>({})

    const [bpm, setBpm] = useState<number>(115)

    return (
        <FileContext.Provider
            value={{
                editorContent,
                setEditorContent,
                fileName,
                setFileName,
                debouncedEditorContent,
                parsedPiece,
                setParsedPiece,
                selectedLine,
                setSelectedLine,
                selectedPlaybackItem,
                setSelectedPlaybackItem,
                doPlayAudio,
                setDoPlayAudio,
                pdfUrl,
                setPdfUrl,
                instruments,
                setInstruments,
                bpm,
                setBpm,
            }}
        >
            {children}
        </FileContext.Provider>
    )
}
